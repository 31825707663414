<template>
  <div class="login-success">
    <div class="flex" style="box-sizing: border-box">
      <div class="login-success-portrait">
        <img :src="webImgUrls" alt="" />
      </div>
      <div class="login-success-right">
        <h1>{{ username }}</h1>
        <p>{{ school }}</p>
      </div>
    </div>
    <p class="login-achievement">本年度学习成果</p>
    <div class="clear login-record">
      <div class="fl" style="color: #00bd92; font-weight: bold">
        已获学时：{{ period.getStudyHour }}
      </div>
      <div class="fl">已完成课程：{{ period.performNumber }}</div>
      <div class="fl">总学时：{{ period.allStudyHour }}</div>
      <div class="fl">已选课程：{{ period.courseNumber }}</div>
    </div>
    <p class="login-schedule" v-if="gradeNum > 0">学习进度 {{ gradeNum }}%</p>
    <p class="login-schedule" v-else>学习进度 0%</p>
    <el-progress :percentage="gradeNum"></el-progress>
    <el-button @click="intoStudyIncident">进入学习</el-button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { userInfo, getCreditStatistics } from '@/api/api';
import store from '@/store';
import { routerPush } from '@/utils/verify';
export default {
  name: 'LoginSuccess',
  data() {
    return {
      period: {
        allStudyHour: '',
        courseNumber: '',
        getStudyHour: '',
        performNumber: ''
      },
      gradeNum: 0
    };
  },
  computed: {
    ...mapGetters(['consumType', 'webImgUrls', 'username', 'school'])
  },
  created() {
    setTimeout(() => {
      this.init();
    }, 20);
  },
  methods: {
    init() {
      const _this = this;
      if (_this.consumType && _this.webImgUrls != '') {
        userInfo({}).then((res) => {
          if (res.code == 200) {
            const { phone, userId, username, headImage, school } = res.data;
            store.commit('setUserwebImgUrl', headImage);
            store.commit('setUserwebUsername', username);
            store.commit('setUserwebUserId', userId);
            store.commit('setUserwebsSchool', school);
            store.commit('setUserwebPhone', phone);
            _this.creditStatistics();
            if (!phone) {
              _this.$phonePopup((res) => {});
            }
          }
        });
      }
    },
    creditStatistics() {
      getCreditStatistics({
        year: new Date().getFullYear()
      }).then((res) => {
        if (res.code == 200) {
          const {
            allStudyHour,
            courseNumber,
            getStudyHour,
            performNumber
          } = res.data;
          this.$set(this, 'period', {
            allStudyHour,
            courseNumber,
            getStudyHour,
            performNumber
          });
          if (getStudyHour / allStudyHour !== Infinity) {
            this.gradeNum = parseFloat(
              ((getStudyHour / allStudyHour) * 100).toFixed(2)
            );
          } else {
            this.gradeNum = 0;
          }
        }
      });
    },
    intoStudyIncident() {
      routerPush(this, '/learn', { type: '0' });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.login {
  &-success {
    padding: 20px;
    box-sizing: border-box;
    &-portrait {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    &-right {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 20px;
      overflow: hidden;
      h1 {
        .fontSize(@TEXT-SIZE-16);
        font-weight: bold;
        line-height: 21px;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p {
        margin-top: 3px;
        .fontSize(@TEXT-SIZE-12);
        color: @TEXT-COLOR-888888;
        line-height: 16px;
      }
    }
  }

  &-achievement {
    margin-top: 43px;
    .fontSize(@TEXT-SIZE-12);
    color: @TEXT-COLOR-888888;
    margin-bottom: 14px;
  }

  &-record {
    color: @TEXT-COLOR-888888;
    .fontSize(@TEXT-SIZE-14);
    div {
      margin-bottom: 15px;
      width: 50%;
    }
  }
  &-schedule {
    color: @TEXT-COLOR-888888;
    .fontSize(@TEXT-SIZE-14);
    margin-top: 15px;
  }
}
/deep/.el-progress-bar {
  padding-right: 0 !important;
  margin-top: 10px;
}
/deep/.el-progress-bar__outer {
  width: 100% !important;
  height: 8px !important;
}
/deep/.el-progress__text {
  display: none !important;
}
/deep/.el-progress-bar__inner {
  background-color: @TEXT-BG-FF5102;
}
/deep/.el-button {
  margin-top: 35px;
  width: 100%;
  border-radius: 20px;
  background: @TEXT-BG-00BD92;
  border: 1px solid @TEXT-BG-00BD92;
  .fontSize(@TEXT-SIZE-16);
  color: @TEXT-COLOR-FFFFFF !important;
}
/deep/.el-button:hover {
  background: @TEXT-BG-00CC9D;
  border: 1px solid @TEXT-BG-00CC9D;
}
</style>